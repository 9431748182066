@import 'foundation';
@import 'settings';

@include foundation-everything;

/*@import 'slick.scss';
@import 'slick-theme.scss';*/

@import 'aos';

/*@import "_foundation-icons.scss";*/

.fullWidth {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: initial;
}

@each $name, $color in $foundation-palette {
  .#{$name}-color {
    color: $color;
  }
  .#{$name}-bg {
    background-color: $color;
  }
}

.hidden {
  display: none;
}

.block {
  display: block;
}

.no-margin {
  margin: 0 !important;
}

.vertical-center {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

#flashMessage {
  text-align: center;
  padding: 1rem;
  background-color: $warning-color;
}

div.thumb, a.thumb {
  background-position: center center;
  &.cover {
    background-size: cover;
  }

  &.contain {
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.gallery_thumb {
  @include breakpoint(small only) {
    position: static;
    transform: none;
  }
}

.pagination {
  margin-top: 2em;
  span {
    display: inline-block;
  }
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

/*//region Youtube slider
.youtube_slider_wrap {
  padding: 6rem 0 2rem;
  background-color: $medium-gray;
}

#youtube_slider, #generic_slider {
  margin-bottom: 5rem !important;
  position: relative;
  .slick-slide {
    background-position: center center;
    background-size: cover;
  }

  .slick-prev, .slick-next {
    width: auto;
    z-index: 2;
    top: auto;
    font-weight: 300;
    bottom: -2rem;
    &:before {
      font-size: 2rem;
    }
    transform: scaleY(2.5);

    @include breakpoint(small only) {
      top: 40%;
      bottom: auto;
      transform: scaleY(4) scaleX(2);
    }
  }

  .slick-prev {
    left: 0;
    @include breakpoint(small only) {
      left: 1rem;
    }
  }

  .slick-next {
    right: 0;
    @include breakpoint(small only) {
      right: 1rem;
    }
  }

  .slick-dots {
    @include breakpoint(small only) {
      display: none !important;
    }
    bottom: -4rem;
    li {
      &.slick-active button {
        color: $primary-color;
      }

      margin: 0 1rem;
      :last-child {
        margin: 0;
      }
      button {
        color: $dark-gray;
        font-size: rem-calc(20);
        &:before {
          display: none;
        }
      }
    }
  }
}

#youtube_slider {
  margin: 0;
}

.YT-slide {
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(/files/images/playtube.svg) no-repeat center center;
    background-size: auto 30%;
    opacity: .2;
    transition: .5s opacity;
  }

  &:hover {
    &:after {
      opacity: .8;
    }
    .text {
      background-color: $black;
    }
  }

  .text {
    position: absolute;
    padding: 1.5em;
    background-color: rgba(100, 100, 100, 0.2);
    transition: .5s background-color;
    left: 0;
    right: 0;
    bottom: 0;
    font-size: 1.2rem;
    color: $white;
    strong {
      font-size: 1.5rem;
      padding-bottom: .5rem;
    }
  }
}

#video_modal {
  background: url(/bower_components/slick-carousel/slick/ajax-loader.gif) no-repeat center center white;
}

//endregion*/

$arrow_height_fallback: 5rem;
$arrow_height: 8vw;

@function get-arrow($color) {
  @return url("data:image/svg+xml;utf8,
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='900'
    height='100'
  >
  <path fill='rgb(#{red($color),#{green($color)},#{blue($color)}})' d='M900 0L675 50l-225 50-225-50L0 0h450z'/>
  </svg>")
}

@mixin after-arrow($color) {
  position: relative;
  border-bottom: 2px solid $color;
  margin-bottom: -2px;
  margin-top: -2px;

  & + * {
    padding-top: $arrow_height_fallback;
    padding-top: $arrow_height;
  }
  &:after {
    content: '';
    position: absolute;
    left: -5px;
    right: -5px;

    bottom: - $arrow_height_fallback;
    bottom: - $arrow_height;

    transform: translateY(-1px);

    height: $arrow_height_fallback;
    height: $arrow_height;

    background-size: 100% 100%;
    pointer-events: none;
    background-repeat: no-repeat;
    z-index: 2;
    background-image: get-arrow($color);
  }
}

body:not(.homepage) > header {
  @include after-arrow($secondary-color);
}

body {
  overflow-x: hidden;
  background-color: $light-gray;
}

body > header {
  background-color: $secondary-color;
  position: relative;

  .pageLogo {
    display: block;
    padding: 2rem;
  }

  .menu {
    text-transform: uppercase;
    &:not(.submenu) {
      a {
        font-size: rem-calc(16);
        font-weight: 600;
        color: $white;
        &:hover {
          color: $primary-color;
        }
      }
    }
  }

  .submenu {
    background-color: $primary-color;
    a {
      color: $white;
      &:hover {
        color: $light-gray !important;
      }
    }
  }

  @include breakpoint(large) {
    .vertical.first-sub {
      z-index: 4;
      top: calc(100% + 5px) !important;
      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: 50%;
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $primary-color transparent;
      }
    }
  }

  @include breakpoint(small only) {
    #responsiveMenuWrap {
      position: relative;
    }

    #responsive-menu {
      padding-top: 1rem;
      padding-bottom: 1rem;
      position: absolute;
      top: 12px;
      left: 1rem;
      right: 1rem;
      z-index: 20;

      background-color: $primary-color;
      &:before {
        content: '';
        position: absolute;
        top: -10px;
        left: 50%;
        left: calc(50% - 10px);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 10px 10px 10px;
        border-color: transparent transparent $primary-color transparent;
      }
    }
  }

  [data-responsive-toggle] {
  }
}

#car_crash_wrap {
  padding-top: 2rem;

  @include breakpoint(medium down) {
    padding-bottom: 1rem;
  }

  #car_crash_image_wrap {
    overflow: hidden;
  }

  img.crash {
    min-height: 10rem;
    object-fit: cover;
    object-position: 54% 50%;
    @include breakpoint(large) {
      width: 80vw;
    }

    @include breakpoint(small only) {
      object-fit: cover;
    }

    max-height: 60vh;
  }

  .sponsor_logos { // Loga posunout o 1rem dolů
    @include breakpoint(large) {
      position: relative;
      z-index: 4;
      top: 1rem;
      margin-top: -1rem;
    }

    @include breakpoint(medium down) {
      a {
        display: block;
        margin: 1rem 0;
      }
    }
  }

  h1, h2 {
    //word-wrap: break-word;
    text-transform: uppercase;
    color: $white;
    @include breakpoint(medium) {
      max-width: 95%;
    }
    @include breakpoint(large) {
      max-width: 80vw;
    }
    margin: auto;
  }

  h1 {
    font-weight: 900;
    line-height: 1.2em;
    span {
      font-size: 1.2em;
    }
    strong {
      color: $primary-color;
    }
    padding-bottom: 2rem;
  }

  h2 {
    @include breakpoint(small only) {
      font-size: rem-calc(14);
    }
  }

  background-color: $secondary-color;

  @include after-arrow($secondary-color);
}

#family_wrap {
  position: relative;
  @include breakpoint(medium down) {
    & > .row {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  background-color: $success-color;
  h1 {
    font-weight: 900;
    text-transform: uppercase;
  }

  p {
    color: $white;
  }

  .button {
    font-weight: bold;
    text-transform: uppercase;

    @include breakpoint(small only) {
      position: absolute;
      bottom: 2rem;
      left: 4rem;
      right: 4rem;
    }
  }

  padding-bottom: 5rem;
}

.home_latest_articles_wrap {
  .hp_latest_articles {
    @include grid-layout(1);
    @include breakpoint(large) {
      @include grid-layout(3);
    }
  }

  h1 {
    font-weight: 900;
  }

  @include breakpoint(small) {
    .read_all {
      @include grid-row;
      .button-wrap {
        @include grid-col(10);
        @include grid-col-pos(1);
      }
    }
  }

  @include breakpoint(large) {
    .read_all {
      @include grid-row;
      .button-wrap {
        @include grid-col(4);
        @include grid-col-pos(4);
      }
    }
  }

  .date {
    display: none;
  }
}

.news_category_1_wrap {
  background-color: $dark-pink;

  h1 {
    margin-top: 5rem;
    margin-bottom: 2rem;
    color: $alert-color;
    text-transform: uppercase;
  }

  .novelty {
    .date {
      font-size: rem-calc(85);
      font-weight: 600;
      background-color: $alert-color;
      color: $white;
      padding: 2rem 0;
    }

    a {
      position: relative;
      background-color: $white;
      padding: 1rem 1rem 5rem;
      display: block;
      border-bottom: 1rem solid $alert-color;
    }

    h3 {
      font-size: rem-calc(35);
      line-height: rem-calc(42);
      font-weight: 300;
      color: $dark-gray;
    }

    p {
      color: $dark-gray;
    }

    .button.hollow {
      border-width: 2px;
      font-weight: 600;
    }

    margin-bottom: 2rem;
  }

  .read_more {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  .read_all {
    margin: 2rem 0 5rem;

    .button {
      background-color: $alert-color;
      text-transform: uppercase;
      font-weight: bold;
      &:hover {
        background-color: scale-color($alert-color, $lightness: -20%);
      }
    }
  }
}

.news_category_2_wrap {
  background-color: $warning-color;
  @include after-arrow($warning-color);

  h1 {
    margin-top: 5rem;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .novelty {
    transition: .5s background-color;

    background-color: rgba($light-orange, 0);

    &:hover {
      background-color: $light-orange;
    }

    a {
      display: block;
      padding-top: 4rem;
      padding-bottom: 2rem;
    }
  }

  .read_more {
    display: none;
  }

  h3 {

  }

  p {
    color: $dark-gray;
  }

  .read_all {
    margin: 0;

    .button {
      text-transform: uppercase;
      font-weight: bold;
    }
  }

  .button-wrap {
    @include breakpoint(small only) {
      margin-bottom: 4rem;
    }

    @include breakpoint(large) {
      position: relative !important;
      top: 1rem;
    }

    z-index: 4;

    .button {
      margin: 0;
    }
  }
}

//region Formuláře
.dynamic-forms-view {
  background-color: $light-gray;

  #DynamicFormAddForm {
    @include grid-row-nest();
  }

  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
  }

  [type=submit] {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 2em;
  }

  .wrap {
    @include breakpoint(medium) {
      @include grid-column(6);
    }
    @include breakpoint(large) {
      @include grid-column(4);
    }
  }

  .formInput {
    //margin-top: .5em;
    //margin-bottom: 1rem;
  }

  .formInput-Label {
    color: $medium-gray;
    font-weight: bold;
  }

  .error-message {
    color: red;
  }

  #{text-inputs()} {
    display: block;
    //margin-top: 0;
    color: $black;
    //padding: rem-calc(10) rem-calc(20);
    font-weight: normal;
  }

  .form-error {
    margin-top: 0;
    //margin-bottom: .5rem;
  }

  .error-message {
    position: relative;
    top: -1rem;
  }

  .form-line-label {
    width: 100%;
    clear: both;
    .formInput-Label {
      margin-top: 4rem;
      font-size: rem-calc(40);
      text-transform: uppercase;
      font-weight: 300;
    }

    strong {
      color: $primary-color;
    }
  }

  .form-text-between {
    clear: both;
  }

  .formType-5 {
    .formInput {
      @include breakpoint(large) {
        margin-bottom: -2rem;
      }
    }
  }
}

.formular-view-3 { // Dotaz
  @include breakpoint(medium down) {
    .form-line-19, .form-line-20 {
      width: 100%;
      margin: 0;
    }
  }

  @include breakpoint(large) {
    @include grid-col(10);
    @include grid-col-pos(1);
  }

  .form-line-20 ~ .wrap {
    width: 100%;
    margin: 0;
  }

  .submit {
    display: none;
  }
}

//endregion

//region Patička
body > .bottom_menu {
  background-color: $secondary-color;

  & > .row {
    padding-top: 5rem;
  }

  h5 {
    text-transform: uppercase;
    margin-bottom: 1rem;

    @include breakpoint(medium down) {
      margin-top: 4rem;
    }
  }

  a {
    color: $white;
    font-weight: 600;
    font-size: rem-calc(14);
  }

  .submenu {
    li {
      a {
        &:before {
          content: '» ';
        }
      }
    }
  }

  .menu > li > a {
    padding: .4rem 0;
  }
}

body > footer {
  background-color: $secondary-color;
  color: $white;
  padding: 4rem;
  font-size: rem-calc(14);
  font-weight: 400;

  @include breakpoint(medium down) {
    font-size: 1.4rem;
  }
}

//endregion

//region Sociální
.menu_19 {
  h5 {
    visibility: hidden;
  }
  font-size: 0;

  @include breakpoint(large) {
    .links {
      &.unplugged {
        z-index: 2;
        text-align: right;
        position: fixed;
        right: 0;
        top: 20vh;
        width: 50px;

        a {
          margin-right: 0;
          opacity: .5;
        }
      }
    }
  }

  a {
    font-size: 0 !important;
    margin-right: 8px;
    display: inline-block;
    width: 50px;
    height: 50px;
    background-size: contain;
    background-color: #fff;
    image-rendering: crisp-edges;
    opacity: .2;
    transition: .5s opacity;
    &:hover {
      opacity: 1 !important;
    }
  }

  .link_wrap {
    display: inline-block;
  }
}

//endregion

.js-drilldown-back > a::before {
  color: $white;
}

#backToTop {
  @include breakpoint(medium down) {
    display: none;
  }
  cursor: pointer;
  font-size: 2rem;
  font-family: serif;
  position: fixed;
  right: 0;
  bottom: 2rem;

  width: 50px;
  height: 50px;
  background-color: #fff;
  opacity: .5;
  transition: .5s opacity;

  &.hiding {
    pointer-events: none;
    opacity: 0;
  }

  &:hover {
    opacity: 1 !important;
  }

  span {
    display: block;
    transform: scaleX(1.6);
    position: relative;
    top: 4px;
    left: 2px;
  }
}

///////////////////////////////////////////////////////

.mainContent {
  @include after-arrow($light-gray);
  padding-bottom: 4rem;
  h1 {
    font-weight: 900;
    text-transform: uppercase;
    margin-top: 4rem;
  }

  h2, h3, h4, h5, h6 {
    margin-top: 2rem;
    margin-bottom: .5rem;
  }

  li {
    &:before {
      content: '■';
      color: $primary-color;
      position: relative;
      width: 0;
      display: inline-block;
      left: -1.25rem;
    }
  }
}

.news-index {
  h1 {
    text-align: center;
  }

  h3 {
    display: block;
  }

  .perex {
    color: $dark-gray;
  }
  .novelty {
    margin-bottom: 4rem;
  }
}

.menu-icon {
  @include hamburger($width: 34px, $height: 30px, $color: $white, $weight: 6px)
}

.content-news-detail {
  .date {
    display: none;
  }
}
